import React, { Component } from "react";
import axios from "axios";

export default class Emails extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      emails: [],
    };
  }

  async componentDidMount() {
    let config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/email`,
      config
    );

    if (data.success) this.setState({ emails: data.emails, loading: false });
    else alert("Something went wrong! Please try again.");
  }

  render() {
    return (
      <div className="container py-5">
        <h1>List of all emails subscribed</h1>
        {this.state.loading ? null : (
          <ul>
            {this.state.emails.map((email, index) => (
              <li key={index}>{email.address}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
