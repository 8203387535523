import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import ReactQuill from "react-quill";

import Spinner from "../components/spinner";

import "react-quill/dist/quill.snow.css";

let categories = [
  { value: "latest-news", label: "Latest News" },
  { value: "editors-pick", label: "Editor's Pick" },
  { value: "opinion", label: "Opinion" },
  { value: "sports", label: "Sports" },
  { value: "business", label: "Business" },
  { value: "entertainment", label: "Entertainment" },
  { value: "life-living", label: "Life & Living" },
  { value: "youth", label: "Youth" },
  { value: "tech-startup", label: "Tech & Startup" },
  { value: "movies-series", label: "Movies & Series" },
  { value: "medical-news", label: "Medical News" },
  { value: "tragedy-news", label: "Tragedy News" },
  { value: "gaming-news", label: "Gaming News" },
  { value: "multimedia", label: "Multimedia" },
  { value: "finance", label: "Finance" },
  { value: "feature-news", label: "Feature News" },
  { value: "international-news", label: "International News" },
  { value: "bangladeshi-news", label: "Bangladeshi News" },
  { value: "breaking-news", label: "Breaking News" },
  { value: "weird-news", label: "Weird News" },
  { value: "travel-living", label: "Travel & Living" },
  { value: "earth", label: "Earth" },
  { value: "law-order", label: "Law & Order" },
  { value: "environment", label: "Environment" },
];

export class Upload extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      title: "",
      subtitle: "",
      content: "",
      category: "",
      longContent: "",
      thumbnail: "",
      source: "",
      sourceLink: "",
      language: "english",
    };
  }

  async submit(event) {
    event.preventDefault();

    this.setState({ loading: true });

    let formData = new FormData();

    formData.append("title", this.state.title);
    formData.append("content", this.state.content);
    formData.append("category", JSON.stringify(this.state.category));
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("language", this.state.language);
    formData.append("source", this.state.source);
    formData.append("sourceLink", this.state.sourceLink);
    formData.append("subtitle", this.state.subtitle);
    formData.append("longContent", this.state.longContent);

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/create`,
        formData,
        config
      );

      if (data.success) {
        alert("Article have been submited");
        window.location.href = "/upload";
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  render() {
    return (
      <div className="my-5 py-5">
        <div className="container">
          <h1 className="text-center mb-3">Upload blogs</h1>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <form>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  value={this.state.title}
                  onChange={(event) =>
                    this.setState({ title: event.target.value })
                  }
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Subtitle</label>
                <input
                  type="text"
                  defaultValue={this.state.subtitle}
                  onChange={(event) =>
                    this.setState({ subtitle: event.target.value })
                  }
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Language
                </label>
                <select
                  defaultValue={this.state.language}
                  className="form-control"
                  onChange={(event) =>
                    this.setState({ language: event.target.value })
                  }
                >
                  <option value="english">English</option>
                  <option value="bangla">Bangla</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Content</label>
                <textarea
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={this.state.content}
                  onChange={(event) =>
                    this.setState({ content: event.target.value })
                  }
                  required
                ></textarea>
              </div>

              <div className="mb-3">
                <label className="form-label">Long Content</label>
                <ReactQuill
                  theme="snow"
                  defaultValue={this.state.longContent}
                  onChange={(value) => this.setState({ longContent: value })}
                  style={{ height: "300px", paddingBottom: "50px" }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Category</label>
                <Select
                  options={categories}
                  isMulti
                  onChange={(value) => this.setState({ category: value })}
                ></Select>
              </div>

              <div className="mb-3">
                <label className="form-label">Thumbnail</label>
                <input
                  type="file"
                  onChange={(event) =>
                    this.setState({ thumbnail: event.target.files[0] })
                  }
                  className="form-control form-control-lg"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Source</label>
                <input
                  type="text"
                  value={this.state.source}
                  onChange={(event) =>
                    this.setState({ source: event.target.value })
                  }
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Source Link</label>
                <input
                  type="text"
                  value={this.state.sourceLink}
                  onChange={(event) =>
                    this.setState({ sourceLink: event.target.value })
                  }
                  required
                  className="form-control"
                />
              </div>

              <div className="my-4 d-grid">
                <button
                  type="submit"
                  className="button"
                  onClick={(event) => this.submit(event)}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default Upload;
