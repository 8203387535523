import React, { Component } from "react";
import axios from "axios";
import dateFormat from "dateformat";

import Spinner from "../components/spinner";

import "./home.css";

export class Home extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      blogs: [],
      count: 0,
    };
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/get/${this.state.count}`
      );

      if (data.success) {
        this.setState({ loading: false, blogs: data.blogs });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  async getBlogs(count) {
    this.setState({ loading: true });

    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/get/${count}`
      );

      if (data.success) this.setState({ loading: false, blogs: data.blogs });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async deleteBlog(id, title) {
    this.setState({ loading: false });

    let conf = window.confirm(`Are you sure you want to delete ${title}?`);
    if (conf) {
      try {
        let config = {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        let { data } = await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/delete/${id}`,
          config
        );

        if (data.success) {
          alert(`${title} have been deleted!`);
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
        this.setState({ loading: false });
        alert("Something went wrong. Please try again!");
      }
    }
  }

  render() {
    return (
      <div className="home my-5 py-5">
        <div className="container">
          <h1>All Blogs</h1>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.blogs.map((blog, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <th>{blog.title}</th>
                    <th>{dateFormat(blog.createdAt)}</th>
                    <th>
                      <a href={`/edit/${blog.slug}`}>Edit</a>
                    </th>
                    <th>
                      <a
                        onClick={() => this.deleteBlog(blog._id, blog.title)}
                        href="#"
                      >
                        Delete
                      </a>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className="d-flex justify-content-end">
            <button
              className="button me-2"
              disabled={this.state.count === 0}
              onClick={() =>
                this.setState({ count: this.state.count - 1 }, () =>
                  this.getBlogs(this.state.count)
                )
              }
            >
              Previous
            </button>
            <button
              className="button"
              onClick={() =>
                this.setState({ count: this.state.count + 1 }, () =>
                  this.getBlogs(this.state.count)
                )
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
