import React, { Component } from "react";

import icon from "../assets/image.png";

import "./navbar.css";

export class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={icon} alt="Icon" width="200" className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {localStorage.getItem("token") ? (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      List Blogs
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="/upload">
                      Upload Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/emails">
                      List Emails
                    </a>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
